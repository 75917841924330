.footer {
    text-align: center;
    height: 2.5rem;
    position: absolute;
    padding-bottom: 2.5rem;
    width: 100%;
    bottom: 0;
}

.row {
    justify-content: center;
}

.footer .darkMode {
    background-color: #343a40 !important;
    color: rgba(255,255,255,.75);
}

.footer .lightMode {
    background-color: #f8f9fa !important;
    color: rgba(0,0,0,.5);
}

.iconButton {
    margin: 15px 5px;
}

.container-fluid {
    margin-left: -5px;
    padding-right: 0px;
}