.theirReview {
    text-align: center;
    width: 50%;
    margin: auto;
}

.theirReview li:nth-child(odd) {
    border-top: 1px solid #AAAAAA;
    border-top-left-radius: 5px;
    border-top-right-radius: 60px;
    border-left: 1px solid #AAAAAA;
    border-right: 1px solid #AAAAAA;
    list-style-type: none;
    font-size: calc(10px + 0.7vw);
    padding-top: 10px;
}

.theirReview li:nth-child(even) {
    text-align: center;
    padding-left: 65%;
    font-style: italic;
    list-style-type: none;
    font-size: calc(6px + 0.7vw);
    border-left: 1px solid #AAAAAA;
    border-right: 1px solid #AAAAAA;
    border-bottom: 1px solid #AAAAAA;
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 5px;
    margin-bottom: 10px;
    padding-bottom: 5px;
}

.reviewBox {
    width: 400px;
    height: 100px;
}

.buttonReview {
    margin-bottom: 20px;
}