.darkMode {
  background: black;
  color: white;
  transition: color 1s ease;
  transition: color 1s ease;
  transition: background 1s ease;
}

.lightMode {
background: white;
color: black;
transition: color 1s ease;
transition: background 1s ease;
}

.nightText {
font-size: .6em;
color: white;
}

.lightText {
font-size: .6em;
color:black;
}

/* .banner {
  margin-bottom: 10px;
  max-height: 80px;
} */

.banner h1 {
  /* font-family: Papyrus, fantasy; */
  font-family: 'Gabriola', 'Zapfino';
  font-size: calc(50px + 0.7vw);
  animation: fadein 4s;
  text-align: center;
  line-height: normal;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
@keyframes slidein {
    from {
      transform: translateY(100%);
    }
  
    to {
      transform: translateY(0%);
    }
  }
  
  .switch {
    position: fixed;
    display: inline-block;
    width: 60px;
    height: 34px;
    right: 0;
    margin:60px 0px;
  }
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #9099a1;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #4fa6ce;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .content {
    min-height: 94vh;
    position: relative;
    padding-bottom: 30px;
  }

  #root {
    margin: -10px;
  }