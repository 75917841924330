.tttsquare {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 90px;
  font-weight: bold;
  line-height: 5px;
  height: 100px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  width: 100px;
}

.tttsquare:focus {
  outline: none;
}
html {
  min-height: 100vh;
}