.gamesPage {
    text-align: center;
}

.comingSoonPic {
    background: url("../img/soon.png") no-repeat center;
}

.ticTacToePic {
    background: url("../img/tictac.png") no-repeat center;
}

.java2dgamePic {
    background: url("../img/java2dgamepic.png") no-repeat center;
}

.cardStyle {
    max-width: 22rem;
    margin: auto;
}

.divider {
    margin-top: 30px;
}

.cardImg{
    background-size:200px;
    min-height: 200px;
    animation: animatezoom .8s;
    margin: auto;
    margin-top: 20px
}

.cardLink{
    color:#17a2b8;;
    text-decoration: underline;
}
.cardLink:hover{
    color: #17a2b8;
    opacity: 0.75;
}