.keiki-party {
    min-height: 97.98vh;
    /* min-height: 100vh; */
    color: black;
}

.kp-hpbanner {
    margin-bottom: 80px;
}

.KP-qrcode {
    width: 200px;
}

.Apps {
    font-size: 18px;
}

.KPList {
    text-align: left;
    list-style: none;
}

.KPInstructions {
    display: flex;
}

.btnSizeUp {
    font-size: 2rem;
    color: black;
    white-space: normal;
}

.ybtn-success {
    background-color: #e5ab55 !important;
    border-color: #E4A0B4 !important;
}

.ybtnSizeUp {
    font-size: 2rem;
    color: black;
    white-space: normal;
    border-color: white !important;
}

.custom-banner{
    /* font-family: Papyrus, fantasy; */
    font-family: 'Zapfino';
    /* font-size: calc(50px + 0.7vw); */
    animation: fadein 4s;
    text-align: center;
    line-height: normal;
    background: rgba(255, 255, 255, 0.75);
}

.ycustom-banner{
    /* font-family: Papyrus, fantasy; */
    font-family: 'Zapfino';
    /* font-size: calc(50px + 0.7vw); */
    animation: fadein 4s;
    text-align: center;
    line-height: normal;
    /* background: rgba(255, 255, 255, 0.75); */
}

.fontBackground {
    background: rgba(255, 255, 255, 0.75);
}

.Trivia-Timer {
    font-size: 4rem;
}

.RRTriviaBackground {
    /* background: url("../img/4057631.jpg"); */
    background: url('../img/4057631.jpg') no-repeat bottom left; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.YTriviaBackground {
    background: url('../img/bgyoonseo.png') no-repeat bottom left; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.btn-primary, .btn-primary:active, .btn-primary:visited {
    /* background-color: #8AB49F !important; */
    background-color: #E4A0B4 !important;
    border-color: #E4A0B4 !important;
}

.btn-success {
    background-color: #e5ab55 !important;
    border-color: #E4A0B4 !important;
}
.ybtn-success:focus {
    background-color: #e5ab55 !important;
    border-color: #E4A0B4 !important;
    box-shadow: 0 0 0 0.2rem #E4A0B4;
}

.boldText {
    font-weight: bold;
}
.usedImageStyle {
    font-size: 4px;
    position: fixed;
    bottom: 0px;
    color: white;
}