#homeCanvas {
    background: pink;
    display: block;
    margin: 0 auto;
}

.darkMode {
    background: black;
}

.lightMode {
    background: white;
}