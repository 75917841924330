#reveal1 {
    opacity: 0;
    -webkit-transform: scale(0.9);
         -moz-transform: scale(0.9);
            -ms-transform: scale(0.9);
             -o-transform: scale(0.9);
                    transform: scale(0.9);
    -webkit-transition: all 1s ease-in-out;
         -moz-transition: all 1s ease-in-out;
            -ms-transition: all 1s ease-in-out;
             -o-transition: all 1s ease-in-out;
                    transition: all 1s ease-in-out;
}
#reveal1.visible {
    opacity: 1;
    -webkit-transform: none;
         -moz-transform: none;
            -ms-transform: none;
             -o-transform: none;
                    transform: none;
}

#reveal2 {
    opacity: 0;
    -webkit-transform: scale(0.9);
         -moz-transform: scale(0.9);
            -ms-transform: scale(0.9);
             -o-transform: scale(0.9);
                    transform: scale(0.9);
    -webkit-transition: all 1s ease-in-out;
         -moz-transition: all 1s ease-in-out;
            -ms-transition: all 1s ease-in-out;
             -o-transition: all 1s ease-in-out;
                    transition: all 1s ease-in-out;
}
#reveal2.visible {
    opacity: 1;
    -webkit-transform: none;
         -moz-transform: none;
            -ms-transform: none;
             -o-transform: none;
                    transform: none;
}

.backgroundPicture {
    background: url("../img/fbpic.jpg") no-repeat center;
    height: 800px;
    animation: slidein 2s;
}